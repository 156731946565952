//redux
import { setData } from '../Redux/Slice/user'
import { useSelector, useDispatch } from 'react-redux'


import { faHand, faHome, faOutdent, faReceipt, faUsers } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Suspense } from "react"
import Loading from "../Components/Loading"
import { Link, Outlet, useNavigate } from "react-router-dom"


import logout from "../Services/Function/logout";



const MenuItem = (props) =>{
    return(<>
        <Link to={props.to}>
            <div className="cursor-pointer flex items-center mt-[20px]">
                <FontAwesomeIcon icon={props.icon}></FontAwesomeIcon>
                <span className="ml-3 font-semibold">{props.text}</span>
            </div>
        </Link>
       
    </>)
}

const MenuItemAction = (props) =>{
    return(<>
        <div onClick={()=>{props.act()}}>
            <div className="cursor-pointer flex items-center mt-[20px]">
                <FontAwesomeIcon icon={props.icon}></FontAwesomeIcon>
                <span className="ml-3 font-semibold">{props.text}</span>
            </div>
        </div>
       
    </>)
}



const BasicLayout = () =>{
    const navigate = useNavigate()
    const user = useSelector((state) => state.user.value)
    const dispatch = useDispatch()

    function logoutFunction(){
        logout().then(res=>{
            if(res){
                // window.location.href = 
                navigate('/login',{replace:true})
                dispatch(setData({}))
            }
        })
    }
    return (
        <>
        <div className="h-[75px] z-[10] w-screen fixed top-0 left-0 bg-gradient-to-b from-purple-200 to-gray-200 flex px-5 items-center shadow-md">
            <span className="font-bold text-2xl">Cause RPC System</span>
        </div>
        <div className="mt-[75px] h-screen bg-gradient-to-b from-gray-200 to-red-200 w-[220px] fixed top-0 flex flex-col pl-[20px] pt-[20px]">
            <div>
                <span className="font-semibold text-xl">{user.user?.username},</span>
                <br></br>
                <span>Loket {user.user?.admin_stationnumber}</span>
            </div>            
            <MenuItem text="Participant" icon={faUsers} to="/"></MenuItem>
            <MenuItem text="Report" icon={faReceipt} to="/report"></MenuItem>
            <div className='mt-[300px]'></div>
            <MenuItemAction act={logoutFunction} text="Log Out" icon={faHand}></MenuItemAction>

            
        </div>
        <div className="mt-[75px] ml-[220px] p-5">
            <Suspense fallback={<Loading type="small" text="Loading page ..." />}>
                <Outlet />
            </Suspense>
        </div>
        </>
    
    )
}

export default BasicLayout